export interface Tool {
  name: string;
  path: string;
}

export interface ToolsList {
  name: string;
  description: string;
  tools: Array<Tool>;
}

const expressionTools: Array<Tool> = [
  {
    name: "Chromatic Expression Images",
    path: "/chroexp",
  },
  {
    name: "BarPlot",
    path: "/barplot",
  },
  {
    name: "LinePlot",
    path: "/lineplot",
  },
  {
    name: "ClusterGram",
    path: "/clustergram",
  },
];

const coexpressionTools: Array<Tool> = [
  {
    name: "Co-Expression Network",
    path: "/corrnetwork",
  },
  {
    name: "Functional Network",
    path: "/functionalnetwork",
  },
  {
    name: "NetworkDrawer",
    path: "/networkdrawer",
  },
  {
    name: "Co-Expression Viewer",
    path: "/correxview",
  },
  {
    name: "Co-Expression Table",
    path: "/corrgenetable",
  },
  {
    name: "Rank Table",
    path: "/ranktable",
  },
];

const analysisTools: Array<Tool> = [
  {
    name: "Differential Exression",
    path: "/diffexp",
  },
  {
    name: "Enrichment",
    path: "/enrichment",
  },
  {
    name: "OrthoPhyloViewer",
    path: "/orthophyloviewer",
  },
  {
    name: "Set Relation",
    path: "/setrel",
  },
];

// 順番通りに表示される
const toolsList: Array<ToolsList> = [
  {
    name: "Expression Tools",
    description: `Visualization of Transcript Per Million (TPM) per Gene and Experimental Condition.`,
    tools: expressionTools,
  },
  {
    name: "Co-Expression Tools",
    description: "Visualization and Analysis of Co-Expression Data",
    tools: coexpressionTools,
  },
  {
    name: "Analysis Tools",
    description: "Utility Tools for Analysis of *Marchantia Polymorpha*",
    tools: analysisTools,
  },
];

export default toolsList;
