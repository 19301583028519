import type { AxiosError } from "axios";

export function generateUuid(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/x/g, () => Math.floor(Math.random() * 16).toString(16))
    .replace(/y/g, () => (Math.floor(Math.random() * 4) + 8).toString(16));
}

export function parse_error(error: AxiosError<any> | undefined): string {
  let message;
  const isTimeout = error?.code === "ECONNABORTED";
  if (isTimeout) {
    message = "Fetching has been failed because of timeout!";
  } else if (error?.response?.data.error_message) {
    message = error.response?.data.error_message;
  } else {
    if (process.env.NODE_ENV === "development") {
      message = `Fetching has been failed with exit code ${error?.response?.status}`;
    } else if (process.env.NODE_ENV === "production") {
      message = `Fetching has been failed. Check learn more or your input!`;
    }
  }

  return message;
}

export const BaseNameConfig = () => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_DEV_BASENAME;
  } else if (process.env.NODE_ENV === "production") {
    return process.env.REACT_APP_PROD_BASENAME;
  }
};

export function createLinkWithBaseName(link: string) {
  if (link.startsWith("/")) {
    const newLink = link.replace(/^\//, "");
    return BaseNameConfig() + newLink;
  }
  return BaseNameConfig() + link;
}
