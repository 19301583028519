import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

// load UI
import { Spinner, Container } from "reactstrap";

// load router config
import routes from "./routes";

// load navbar
import HeaderNavbar from "./components/HeaderNavbar";

// load not found
import NotFoundPage from "./pages/NotFoundPage";
import { BaseNameConfig } from "./libs/utils";

function TitleConfig(): string | undefined {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_DEV_TITLE;
  } else if (process.env.NODE_ENV === "production") {
    return process.env.REACT_APP_PROD_TITLE;
  }
}

const App = () => {
  const title = TitleConfig();
  React.useEffect(() => {
    document.title = title!;
  }, [title]);

  const thisYear = new Date().getFullYear();

  return (
    <BrowserRouter basename={BaseNameConfig()}>
      <div className="wrapper" style={styles.wrapper}>
        <header>
          <HeaderNavbar />
        </header>
        <main style={styles.main}>
          <React.Suspense
            fallback={
              <Container>
                <Spinner color="dark" />
              </Container>
            }
          >
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              <Route component={NotFoundPage} />
            </Switch>
          </React.Suspense>
        </main>
        <footer style={styles.footer}>
          <span style={{ marginRight: "0.1rem" }}>
            Copyright &copy; 2020-{thisYear} Licensed under
          </span>
          <a
            style={{ marginRight: "0.1rem" }}
            href="https://creativecommons.org/licenses/by/4.0/deed.en"
          >
            Creative Commons Attribution 4.0 International license (CC-BY 4.0)
          </a>
          [
          <a href="https://academic.oup.com/pcp/advance-article/doi/10.1093/pcp/pcac129/6694961">
            cite
          </a>
          ]
        </footer>
      </div>
    </BrowserRouter>
  );
};

export default App;

// css
const styles = {
  wrapper: {
    display: "flex" as const,
    flexDirection: "column" as const,
    minHeight: "100vh" as const,
  },
  main: {
    margin: "1rem" as const,
  },
  footer: {
    marginTop: "auto" as const,
    fontSize: 10 as const,
    textAlign: "center" as const,
    backgroundColor: "whitesmoke" as const,
  },
};
