import React from "react";

import { Link } from "react-router-dom";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import toolsList from "../tools";

import Logo from "../logo";
import { MARPOLBASE_URL } from "../defaultValue";
import { createLinkWithBaseName } from "../libs/utils";

const ToolsDropDown = (props) => {
  const dropDownItems = props.tools.map((obj, idx) => (
    <DropdownItem key={idx} tag={Link} to={obj.path}>
      {obj.name}
    </DropdownItem>
  ));

  return (
    <UncontrolledDropdown>
      <DropdownToggle nav caret>
        {props.name}
      </DropdownToggle>
      <DropdownMenu right>{dropDownItems}</DropdownMenu>
    </UncontrolledDropdown>
  );
};

const HeaderNavbar: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const ToolsDropDowns = toolsList.map((obj, idx) => (
    <ToolsDropDown key={idx} id={idx} name={obj.name} tools={obj.tools} />
  ));

  return (
    <>
      <Navbar color="light" light expand="md">
        <NavbarBrand>
          <Link to="/">
            <Logo />
          </Link>
          <a href={MARPOLBASE_URL} target="_blank" rel="noreferrer">
            <img
              src={createLinkWithBaseName("marpolbase_logo.png")}
              alt="marpolbase"
              width={45}
              height={45}
            />
          </a>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="navbar mr-auto">
            {ToolsDropDowns}
            <UncontrolledDropdown>
              <DropdownToggle nav caret>
                DataSource
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag={Link} to="/datasource">
                  Sra Datasource
                </DropdownItem>
                <DropdownItem tag={Link} to="/downloads">
                  Downloads
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <Nav className="navbar ml-auto">
            <NavItem>
              <UncontrolledDropdown>
                <DropdownToggle nav caret>
                  About
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to="/about/aboutus">
                    About Us
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/about/methods">
                    Methods
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/about/faqs">
                    FAQs
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/about/release">
                    Release
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default HeaderNavbar;
