import React from "react";

// router config and lazy loading

// Home
const Home = React.lazy(() => import("./pages/Home"));

// Expression tools
const BarPlot = React.lazy(() => import("./pages/ExpressionTools/BarPlot"));
const ClusterGram = React.lazy(
  () => import("./pages/ExpressionTools/ClusterGram")
);
const ChromaticExpression = React.lazy(
  () => import("./pages/ExpressionTools/ChromaticExpression")
);
const LinePlot = React.lazy(() => import("./pages/ExpressionTools/LinePlot"));

// co-expression tools
const CorrNetwork = React.lazy(
  () => import("./pages/CoExpressionTools/CorrNetwork")
);

const FunctionalNetwork = React.lazy(
  () => import("./pages/CoExpressionTools/FunctionalNetwork")
);

const NetworkDrawer = React.lazy(
  () => import("./pages/CoExpressionTools/NetworkDrawer")
);

const CorrExView = React.lazy(
  () => import("./pages/CoExpressionTools/CorrExView")
);
const CorrGeneTable = React.lazy(
  () => import("./pages/CoExpressionTools/CorrGeneTable")
);

const RankTable = React.lazy(
  () => import("./pages/CoExpressionTools/RankTable")
);

// analysis tools
const DiffrentialExpression = React.lazy(
  () => import("./pages/AnalysisTools/DifferentialExpression")
);
const Enrichment = React.lazy(() => import("./pages/AnalysisTools/Enrichment"));
const GOTerm = React.lazy(() => import("./pages/AnalysisTools/GOTerm"));
const OrthoPhyloViewer = React.lazy(
  () => import("./pages/AnalysisTools/OrthoPhyloViewer")
);

const SetRelation = React.lazy(
  () => import("./pages/AnalysisTools/SetRelation")
);

// resources
const DataSource = React.lazy(() => import("./pages/DataSources/DataSource"));
const DownloadPage = React.lazy(() => import("./pages/DataSources/Download"));
const SraIdDetail = React.lazy(() => import("./pages/DataSources/SraIdDetail"));
const BioSampleDetail = React.lazy(
  () => import("./pages/DataSources/BioSampleDetail")
);

// about
const Methods = React.lazy(() => import("./pages/About/Methods"));
const AboutUs = React.lazy(() => import("./pages/About/AboutUs"));
const Faq = React.lazy(() => import("./pages/About/Faq"));
const Release = React.lazy(() => import("./pages/About/Release"));

// internal
const InternalCharomaticExpression = React.lazy(
  () => import("./pages/internal/InternalChromaticExpression")
);
const InternalBarPlot = React.lazy(
  () => import("./pages/internal/InternalBarplot")
);
const InternalCorrNetwork = React.lazy(
  () => import("./pages/internal/InternalCorrNetwork")
);
const InternalOrthoPhylo = React.lazy(
  () => import("./pages/internal/InternalOrthophylo")
);

// experimental
const ChromaticExpressionExportPage = React.lazy(
  () => import("./pages/Experimental/ChromaticExpressionExport")
);

interface Route {
  path: string;
  exact: boolean;
  name: string;
  component: React.LazyExoticComponent<React.VFC<{}>>;
}

const routes: Route[] = [
  { path: "/", exact: true, name: "Home", component: Home },
  { path: "/barplot", exact: false, name: "BarPlot", component: BarPlot },
  {
    path: "/clustergram",
    exact: false,
    name: "ClusterGram",
    component: ClusterGram,
  },
  {
    path: "/chroexp",
    exact: false,
    name: "ChromaticExpressionImage",
    component: ChromaticExpression,
  },
  { path: "/lineplot", exact: false, name: "LinePlot", component: LinePlot },
  {
    path: "/corrnetwork",
    exact: false,
    name: "CorrNetwork",
    component: CorrNetwork,
  },
  {
    path: "/functionalnetwork",
    exact: false,
    name: "FunctionalNetwork",
    component: FunctionalNetwork,
  },
  {
    path: "/networkdrawer",
    exact: false,
    name: "NetworkDrawer",
    component: NetworkDrawer,
  },
  {
    path: "/correxview",
    exact: false,
    name: "CorrExView",
    component: CorrExView,
  },
  {
    path: "/corrgenetable",
    exact: false,
    name: "CorrGeneTable",
    component: CorrGeneTable,
  },
  {
    path: "/ranktable",
    exact: false,
    name: "RankTable",
    component: RankTable,
  },
  {
    path: "/diffexp",
    exact: false,
    name: "DiffExp",
    component: DiffrentialExpression,
  },
  {
    path: "/enrichment",
    exact: true,
    name: "Enrichemnt",
    component: Enrichment,
  },
  {
    path: "/goterm/:id",
    exact: false,
    name: "GOTerm",
    component: GOTerm,
  },
  {
    path: "/orthophyloviewer",
    exact: false,
    name: "OrthoPhyloViewer",
    component: OrthoPhyloViewer,
  },
  {
    path: "/setrel",
    exact: false,
    name: "SetRelation",
    component: SetRelation,
  },
  {
    path: "/datasource",
    exact: false,
    name: "DataSource",
    component: DataSource,
  },
  {
    path: "/downloads",
    exact: false,
    name: "Downloads",
    component: DownloadPage,
  },
  {
    path: "/sra/:id",
    exact: false,
    name: "SRA",
    component: SraIdDetail,
  },
  {
    path: "/biosample/:id",
    exact: false,
    name: "BioSample",
    component: BioSampleDetail,
  },
  {
    path: "/about/methods",
    exact: false,
    name: "Methods",
    component: Methods,
  },
  {
    path: "/about/aboutus",
    exact: false,
    name: "About Us",
    component: AboutUs,
  },
  {
    path: "/about/faqs",
    exact: false,
    name: "FAQs",
    component: Faq,
  },

  {
    path: "/about/release",
    exact: false,
    name: "Release",
    component: Release,
  },
  {
    path: "/internal/chroexp/:id",
    exact: false,
    name: "InternalChromaticExpressionImages",
    component: InternalCharomaticExpression,
  },
  {
    path: "/internal/barplot/:id",
    exact: false,
    name: "InternalBarplot",
    component: InternalBarPlot,
  },
  {
    path: "/internal/orthophylo/:id",
    exact: false,
    name: "InternalOrthoPhylo",
    component: InternalOrthoPhylo,
  },
  {
    path: "/internal/corrnet/:id",
    exact: false,
    name: "InternalCorrNetwork",
    component: InternalCorrNetwork,
  },
  {
    path: "/experimental/chroexp",
    exact: false,
    name: "ChromaticExpressionImageExport",
    component: ChromaticExpressionExportPage,
  },
];

export default routes;
