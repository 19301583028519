import React from "react";
import Logo from "../logo";

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Logo width="36rem" />
      <h1>404</h1>
      <h3>Sorry, that page can't be found.</h3>
    </div>
  );
};

export default NotFoundPage;
