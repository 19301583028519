// DEFAULT CONDITION
// pk -> condition name
//     51 -> Thallus tak1 14 day
//     1 -> Thallus tak1 9 day
//     2 -> Antheridiophore tak1
//     4 -> Archegoniophore tak2
//     53 -> Sporeling Cam1 * Cam2 96 hr
//     54 ->  Sporeling Cam1 * Cam2 72 hr
//     55 ->  Sporeling Cam1 * Cam2 24 hr
//     56 ->  Sporeling Cam1 * Cam2 0 hr
//     57 ->  Sporeling Cam1 * Cam2 48 hr
//     11 -> Gemma Cup tak1
//     12 -> midrib tak1
//     49 -> gametophytic apical cell BC3 + tak1
//     50 -> sporophyte BC3 + tak1

export const DEFAULT_CONDITIONS = [
  51, 1, 11, 12, 49, 2, 4, 56, 55, 57, 54, 53, 50,
];

// DEFAULT GENES

export const DEFAULT_GENE_IDS: string[] = [
  "Mp7g11980",
  "Mp2g00890",
  "Mp7g02370",
  "Mp3g17350",
  "Mp4g15560",
  "Mp7g16430",
  "Mp6g03670",
  "Mp6g21470",
  "Mp8g18080",
  "Mp5g03020",
];

export const DEFAULT_GENE_ID = "Mp3g04030";

// READABLE TIMEOUT

export const TIMEOUT_1s = 1000;
export const TIMEOUT_10s = 10 * TIMEOUT_1s;
export const TIMEOUT_100s = 100 * TIMEOUT_1s;

export const TIMEOUT_1min = 60 * TIMEOUT_1s;
export const TIMEOUT_5min = 5 * TIMEOUT_1min;

export const MODE_BAR_BUTTON_TO_REMOVE = {
  modeBarButtonsToRemove: [
    "zoom2d",
    "pan2d",
    "lasso2d",
    "resetScale2d",
    "resetViews",
    "toggleSpikelines",
    "hoverClosestCartesian",
    "hoverCompareCartesian",
  ],
};

export const MARPOLBASE_URL = "https://marchantia.info/";
